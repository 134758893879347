import { FunctionComponent, useEffect, useLayoutEffect, useState, useRef, useCallback } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Detections } from "./Detections";

interface ResultsProps {
    image: Blob;
    detections: Detections;
    onExit: () => any;
}

const ResultsScreen: FunctionComponent<ResultsProps> = ({ image, detections, onExit }) => {
    return <>
        <div className="ViewFinder">
            <ResultsImage image={image} detections={detections} />
        </div>
        <div className="CameraControl">
            <span onClick={() => onExit()} className="fa-layers fa-fw">
                <FontAwesomeIcon className="camera-circle" icon={faCircle} />
                <FontAwesomeIcon className="camera-icon" icon={faXmark} size="sm" />
            </span>
        </div>
    </>
}

interface ResultImageProps {
    image: Blob;
    detections: Detections;
}

const ResultsImage: FunctionComponent<ResultImageProps> = ({ image, detections }) => {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(
        () => {
            const url = URL.createObjectURL(image);
            setImageUrl(url);
            return () => { URL.revokeObjectURL(url) }
        },
        [image]
    )

    const imageBitmap = useImageBitmap(image);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawCanvas = useCallback(
        async () => {
            if (imageBitmap === undefined) {
                return;
            }

            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }

            const ctx = canvas.getContext("2d");
            if (ctx === null) {
                return;
            }

            canvas.width = imageBitmap.width || 0;
            canvas.height = imageBitmap.height || 0;

            ctx.drawImage(imageBitmap, 0, 0);
            ctx.strokeStyle = "red";

            for (const res of detections.detection_results) {
                const box = res.box;
                ctx.strokeRect(box.x1, box.y1, (box.x2 - box.x1), (box.y2 - box.y1))
            }

        },
        [canvasRef.current, image, imageBitmap]
    )

    useEffect(() => { drawCanvas() }, [drawCanvas]);

    if (imageUrl === undefined) {
        return <></>
    }

    return <canvas ref={canvasRef} />
}

const useImageBitmap = (image: Blob) => {
    const [bmp, setBmp] = useState<ImageBitmap>();

    useEffect(
        () => {
            let _bmp: ImageBitmap | null = null;

            createImageBitmap(image).then(createdBitmap => {
                setBmp(createdBitmap);
                _bmp = createdBitmap;
            });

            return () => _bmp?.close();
        },
        []
    )

    return bmp;
}

export default ResultsScreen;