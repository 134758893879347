import { FunctionComponent, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BlobImage } from "./BlobImage";
import { Detections } from "./Detections";

type FinishedCallback = (detectionResults: Detections) => any;

interface UploadingProps {
    screenshot: Blob;
    onFinished: FinishedCallback,
}

const UPLOAD_URL = "/api/image-upload/"

const UploadingScreen: FunctionComponent<UploadingProps> = (props) => {
    const uploadScreenshot = async (screenshot: Blob) => {
        const file = new File([screenshot], "screenshot.jpg", { type: "image/jpeg" });
        const form_data = new FormData()
        form_data.append('image', file)

        const res = await window.fetch(
            UPLOAD_URL, {
            method: 'POST',
            body: form_data,
        }
        )
        if (!res.ok) {
            throw new Error("Error processing image.");
        }
        return await res.json() as Detections;
    }

    const processScreenshot = async () => {
        const detections = await uploadScreenshot(props.screenshot);
        props.onFinished(detections);
    }

    useEffect(() => { processScreenshot() }, [props.screenshot]);

    return <>
        <div className="ViewFinder">
            <BlobImage imageBlob={props.screenshot} className="camera-flash" />
        </div>
        <div className="CameraControl">
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon className="camera-circle" icon={faCircle} />
                <FontAwesomeIcon className="camera-icon" icon={faSpinner} size="sm" spin={true} />
            </span>
        </div>
    </>
}

export default UploadingScreen;