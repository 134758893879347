import { FunctionComponent } from "react"

interface BlobImageProps {
    imageBlob: Blob;
    alt?: string;
    className?: string;
}

export const BlobImage: FunctionComponent<BlobImageProps> = (props) => {
    const imageUrl = URL.createObjectURL(props.imageBlob);
    return <img alt={props.alt} src={imageUrl} className={props.className}></img>
}
