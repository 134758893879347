import { useState, useCallback } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import Camera from "./Camera";
import UploadingScreen from "./UploadingScreen";
import ResultsScreen from "./ResultsScreen";
import './styles/bulma.scss';
import "./App.css"
import { Detections } from "./Detections";

enum State {
  Camera,
  Uploading,
  Results,
  Error
}

interface CameraState {
  appState: State.Camera
}

interface UploadingState {
  appState: State.Uploading,
  screenshot: Blob
}

interface ResultsState {
  appState: State.Results,
  detections: Detections,
  screenshot: Blob
}

type AppState = CameraState | UploadingState | ResultsState;

function App() {
  const [state, setState] = useState<AppState>({ appState: State.Camera });


  const renderContent = useCallback(() => {
    if (state.appState === State.Camera) {
      return <Camera
        onCapture={(screenshot: Blob) => {
          setState({
            appState: State.Uploading,
            screenshot: screenshot
          });
        }}
      />
    } else if (state.appState === State.Uploading) {
      return <UploadingScreen
        screenshot={state.screenshot}
        onFinished={(detectionResults: Detections) => {
          setState({
            appState: State.Results,
            detections: detectionResults,
            screenshot: state.screenshot
          })
        }}
      />
    } else {
      return <ResultsScreen
        detections={state.detections}
        onExit={() => { setState({ appState: State.Camera }) }}
        image={state.screenshot}
      />
    }
  }, [state]);

  return (
    <div className="AfidCam">
      <nav>
        <h1 className="title is-2 logo">
          <FontAwesomeIcon icon={faCameraRetro} />
          {" AFID Cam"}
        </h1>
        <h1 className="title info" style={{ justifySelf: "flex-end" }}>
          <a href="https://afid.io">
            <FontAwesomeIcon icon={faCircleInfo} />
          </a>
        </h1>
      </nav>
      {renderContent()}
    </div>
  );
}

export default App;
