import { FunctionComponent, useRef, useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Webcam from "react-webcam";

type ScreenShotCallback = (screenShot: Blob) => any;

interface CameraProps {
    onCapture: ScreenShotCallback;
}

async function b64toBlob(base64: string) {
    const res = await window.fetch(base64);
    return await res.blob();
}

const Camera: FunctionComponent<CameraProps> = (props) => {
    const webCamRef = useRef<Webcam>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const [cameraActive, setCameraActive] = useState(true);

    const takeScreenshot = async () => {
        if (webCamRef.current !== null) {
            const screenshot = webCamRef.current.getScreenshot();
            if (screenshot !== null) {
                const screenshotBlob = await b64toBlob(screenshot);
                props.onCapture(screenshotBlob);
            }
        }
    }

    useEffect(() => {
        const disableCamera = () => { setCameraActive(false); }
        const enableCamera = () => { setCameraActive(true); }

        window.addEventListener('blur', disableCamera);
        window.addEventListener('focus', enableCamera);

        return () => {
            window.removeEventListener('blur', disableCamera);
            window.removeEventListener('focus', enableCamera);
        }
    }, []);


    return <>
        <div className="ViewFinder" ref={divRef}>
            {cameraActive && <Webcam
                screenshotFormat="image/jpeg"
                ref={webCamRef}
                videoConstraints={{ facingMode: "environment" }}
            />}
        </div>
        <div className="CameraControl">
            <span onClick={takeScreenshot} className="fa-layers fa-fw">
                <FontAwesomeIcon className="camera-circle" icon={faCircle} />
                <FontAwesomeIcon className="camera-icon" icon={faCamera} size="sm" />
            </span>
        </div>
    </>
}

export default Camera;